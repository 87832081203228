import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  adminGetAccounts,
  adminUpdateAccount,
  adminCheckAccountPasswordChangeStatus,
  clearSuccessErrorProps,
} from "../../../redux/actions/adminActions";
import {
  // filterAccountPlatform,
  filterAccountType,
  filterBaseCurrency,
} from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { Container, Row, Col, Spinner, Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import { randomPasswordComplex } from "../../../utils/utils";
import { NotificationManager } from "react-notifications";
import AccountDetails from "../../../components/Modals/AccountDetails/AccountDetails";

const TradingAccounts = props => {
  const [state, setState] = useState({
    editAccountErrors: "",
    showAddEditAccountDetailsModal: false,
    disableEditAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [accountsLive, setLiveAccounts] = useState(null);
  const [usersLive, setLiveUsers] = useState(null);
  const [accountsDemo, setDemoAccounts] = useState(null);
  const [usersDemo, setDemoUsers] = useState(null);

  useEffect(() => {
    triggerAdminGetAccounts();
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      createNotification("success");

      let closeModal = true;

      if (props.success.passwordChanged) {
        closeModal = false;
        setTimeout(
          () =>
            props.adminCheckAccountPasswordChangeStatus(
              props.success.accountId
            ),
          5000
        );
      }
      if (props.success.passwordChangeChecked) {
        if (props.success.data.status === "approved")
          createNotification("passwordChanged");
        else createNotification("passwordChangeFailed");
      }

      if (closeModal) {
        clearSuccessErrorProps();
        handleCloseModal();
        triggerAdminGetAccounts();
      }
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.account !== undefined
    ) {
      createNotification("error");

      setState(prevState => {
        return {
          ...prevState,
          editAccountErrors: props.errors.account,
        };
      });
      clearSuccessErrorProps();
    }
  }, [props]);

  const triggerAdminGetAccounts = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetAccounts("all", "live", "approved")
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setLiveUsers(users);

        setLiveAccounts(res.data.accounts);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));

    adminGetAccounts("all", "demo", "approved")
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setDemoUsers(users);

        setDemoAccounts(res.data.accounts);
      })
      .catch(err => console.error(err));
  };

  const handlePasswordChange = () => {
    document.getElementById("editPassword").value = randomPasswordComplex(10);
  };

  const handleUpdateAccount = e => {
    let name = e.target.name;
    let value = e.target.value;

    const selectedAccount = state.selectedAccount;
    selectedAccount[name] = value;
    setState(prevState => {
      return { ...prevState, selectedAccount };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showAddEditAccountDetailsModal: false,
        disableEditAccountBtn: false,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedAccount = state.selectedAccount;
    selectedAccount.password = document.getElementById("editPassword").value;

    // if (
    //   selectedAccount.status === "approved" &&
    //   (!selectedAccount.number || selectedAccount.password.trim() === "")
    // ) {
    //   alert("You cannot approve account without acc# and password");
    //   return false;
    // }

    if (
      selectedAccount.status === "rejected" &&
      (!selectedAccount.comment || selectedAccount.comment.trim() === "")
    ) {
      alert("You cannot reject account without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditAccountBtn: true };
    });

    if (form.checkValidity()) {
      props.adminUpdateAccount(selectedAccount);
    }
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the account.",
          "Account Update",
          5000
        );
        break;
      case "passwordChanged":
        NotificationManager.success(
          "You have successfully changed the password for this account.",
          "Password Change"
        );
        break;
      case "passwordChangeFailed":
        NotificationManager.error(
          "Something went wrong while changing the password. Please, try again.",
          "Error",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.baseCurrency === filterVal);
    }
    return data;
  };

  const customFilterAccountPlatform = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.platform === filterVal);
    }
    return data;
  };

  const customFilterAccountType = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.type === filterVal);
    }
    return data;
  };

  const columnsLive = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "type",
      text: "ACC. TYPE",
      sort: true,
      filter: selectFilter({
        options: filterAccountType,
        onFilter: customFilterAccountType,
      }),
    },
    {
      dataField: "number",
      text: "ACC. NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      // sort: true,
      // filter: selectFilter({
      //   options: filterAccountPlatform,
      //   onFilter: customFilterAccountPlatform,
      // }),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreatedText",
      text: "CREATED",
      sort: true,
    },
    {
      dataField: "timeApprovedText",
      text: "APPROVED",
      sort: true,
    },
  ];

  const columnsDemo = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "type",
      text: "ACC. TYPE",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "number",
      text: "ACC. NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      // sort: true,
      // filter: selectFilter({
      //   options: filterAccountPlatform,
      //   onFilter: customFilterAccountPlatform,
      // }),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      sort: true,
      classes: "text-right",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreatedText",
      text: "CREATED",
      sort: true,
    },
    {
      dataField: "timeApprovedText",
      text: "APPROVED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeApprovedText",
      order: "asc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setState(prevState => {
        return {
          ...prevState,
          showAddEditAccountDetailsModal: true,
          selectedAccount: row,
        };
      });
    },
  };

  let dataLive = [];
  if (!!accountsLive) {
    accountsLive.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      accountsLive[keyIndex].timeCreatedText = moment(
        accountsLive[keyIndex].timeCreated * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accountsLive[keyIndex].timeApprovedText = moment(
        accountsLive[keyIndex].timeApproved * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accountsLive[keyIndex].balance = parseFloat(
        accountsLive[keyIndex].balance
      ).toFixed(2);

      if (usersLive && usersLive[accountsLive[keyIndex].userId]) {
        accountsLive[keyIndex].fullName = `${
          usersLive[accountsLive[keyIndex].userId].name
        } ${usersLive[accountsLive[keyIndex].userId].surname}`;
        accountsLive[keyIndex].email =
          usersLive[accountsLive[keyIndex].userId].email;
      }

      dataLive.push(accountsLive[keyIndex]);
    });
  }

  let dataDemo = [];
  if (!!accountsDemo) {
    accountsDemo.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      accountsDemo[keyIndex].timeCreatedText = moment(
        accountsDemo[keyIndex].timeCreated * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accountsDemo[keyIndex].timeApprovedText = moment(
        accountsDemo[keyIndex].timeApproved * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      if (usersDemo && usersDemo[accountsDemo[keyIndex].userId]) {
        accountsDemo[keyIndex].fullName = `${
          usersDemo[accountsDemo[keyIndex].userId].name
        } ${usersDemo[accountsDemo[keyIndex].userId].surname}`;
        accountsDemo[keyIndex].email =
          usersDemo[accountsDemo[keyIndex].userId].email;
      }

      dataDemo.push(accountsDemo[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Trading Accounts{" "}
            {state.loading ? <Spinner animation="border" /> : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="live"
            transition={false}
            id="account-approved-tabs"
          >
            <Tab eventKey="live" title="LIVE" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={dataLive}
                columns={columnsLive}
                defaultSorted={defaultSorted}
                hover={true}
                pagination={pagination}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
            <Tab eventKey="demo" title="DEMO" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={dataDemo}
                columns={columnsDemo}
                defaultSorted={defaultSorted}
                hover={true}
                pagination={pagination}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
          </Tabs>

          {state.showAddEditAccountDetailsModal ? (
            <AccountDetails
              triggerGetAccounts={triggerAdminGetAccounts}
              handleModalClose={handleCloseModal}
              createNotification={createNotification}
              account={state.selectedAccount}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

TradingAccounts.propTypes = {
  auth: PropTypes.object.isRequired,
  adminUpdateAccount: PropTypes.func.isRequired,
  adminCheckAccountPasswordChangeStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  adminUpdateAccount,
  adminCheckAccountPasswordChangeStatus,
})(TradingAccounts);
